import React, {SyntheticEvent, useState} from 'react';
import {Formik, Form, Field, FormikValues} from 'formik';
import './PrintForm.css'
import axios from 'axios';

const MAX_IMAGE_SIZE = 16 * 8;

function PrintForm ({api_base_url}: { api_base_url: string }) {
    console.log(api_base_url);
    const submitButton = React.useRef<HTMLButtonElement>(null);
    const pictureField = React.useRef<HTMLInputElement>(null);
    const [picture, setPicture] = useState<string | undefined>(undefined);

    const preloadImage = (e: Event) => {
        let target: HTMLInputElement | null, files: FileList | null;
        if ((target = e.target as HTMLInputElement)) {
            if ((files = target.files)) {
                let file: File | null;
                if ((file = files[0])) {
                    const reader = new FileReader();
                    reader.onload = () => {
                        // convert image file to base64 string
                        setPicture(reader.result as string | undefined);
                    };
                    reader.readAsDataURL(file);
                } else {
                    setPicture(undefined)
                }
            }
        }
    };

    const submitForm = (values: FormikValues) => {
        if (submitButton.current) {
            submitButton.current.disabled = true;
        }

        axios.post(
            `${api_base_url}/`,
            {...values, 'picture': picture}
        )
            .finally(() => {
                if (submitButton.current) {
                    submitButton.current.disabled = false;
                }
            })
    };

    const previewLoaded = (e: SyntheticEvent<HTMLImageElement>) => {
        const target = e.target as HTMLImageElement
        if (target.naturalWidth > MAX_IMAGE_SIZE || target.naturalWidth > MAX_IMAGE_SIZE) {
            alert(`Picture too big! Maximum allowed size is ${MAX_IMAGE_SIZE}x${MAX_IMAGE_SIZE} but actual ${target.naturalWidth}x${target.naturalWidth}`);
            if (pictureField.current) {
                pictureField.current.value = '';
                setPicture(undefined);
            }
        }
    };

    return (
        <div className="PrintForm">
            <h1>Print picture</h1>
            <Formik
                initialValues={{
                    position: {
                        x: 0,
                        y: 0,
                        z: 0,
                    },
                }}
                onSubmit={submitForm}
            >
                <Form>
                    <div>
                        <img
                            src={picture}
                            alt="Preview"
                            width={MAX_IMAGE_SIZE}
                            height={MAX_IMAGE_SIZE}
                            onLoad={previewLoaded}
                        />
                    </div>
                    <div>
                        <Field name="picture" type="file" accept="image/png" onChange={preloadImage} innerRef={pictureField} />
                    </div>
                    <div>
                        x: <Field name="position.x" type="number" min="-99999" max="99999"/>
                        z: <Field name="position.z" type="number" min="-99999" max="99999"/>
                        y: <Field name="position.y" type="number" min="-99999" max="99999"/>
                    </div>
                    <div>
                        <button ref={submitButton} type="submit">Print</button>
                    </div>
                </Form>
            </Formik>
        </div>
    );
}

export default PrintForm;
