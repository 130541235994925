import React from 'react';
import logo from './logo.svg';
import './App.css';
import PrintForm from "./PrintForm";

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <PrintForm api_base_url={process.env.REACT_APP_API_BASE_URL}/>
      </header>
      <footer className="App-footer">{process.env.NODE_ENV}</footer>
    </div>
  );
}

export default App;
